
.tags-input {
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 39px !important;
  line-height: 30px;
  width: 100%;
  padding: 2px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  &:focus-within {
    border: 1px solid #0052cc;
  }
  input {
    flex: 1;
    border: none;
    font-size: 14px;
    &:focus {
      outline: transparent;
    }
  }
  input::placeholder {
    padding: 0 8px;
    opacity: 0.5;
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.tag {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.06);
  margin: 1px;
  
  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #00000051;
    border-radius: 50%;
    cursor: pointer;
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: 100%;
  }
}