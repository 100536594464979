.side-nav
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: 15rem
    z-index: 2

.side-nav-container
    background-color: blue
    background-color: #ffffff
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px
    background: linear-gradient(#001628, #03345C)
    display: flex
    flex-direction: column
    align-content: center
    height: 100%
    z-index: 2

.top-nav
    width: 100%
    height: 100%
    padding: 0 1.5rem
    background-color: #ffffff
    box-shadow: rgba(0, 0, 0, 0.1) 4px 0px 12px
    background: linear-gradient(90deg, #001628, #03345C)
    color: white
    display: flex
    justify-content: space-between
    align-items: center
    box-sizing: border-box
    z-index: 0

.topnav-profile
    display: flex
    align-items: center

.hamburger
    margin-right: 20px
    display: none !important

.ant-drawer-body
    padding: 0 !important

.user-details
    display: flex
    flex-direction: column
    align-items: center
    min-height: 8rem

.nav-menu
    text-decoration: none
    color: black

.drawer
    padding: 0
    display: none
.ant-drawer-mask
    display: none

@media only screen and (max-width: 1100px)
    .side-nav
        left: -15rem
    .hamburger
        display: block !important
    .drawer
        display: block
    .ant-drawer-mask
        display: block

.ant-breadcrumb-link, .ant-breadcrumb-separator
    color: #99A1A9 !important