* {
  margin: 0;
  font-family: "Roboto" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Temporary styling fix. It should he removed once AntD table is used through out the project. */
.table {
  width: 100%;
  border: 1px solid #EEEEEE;
  overflow: scroll;
}

.table-header {
  display: flex;
  width: 100%;
  background: #e8e8e8;
  padding: 18px 0;
  /* color: black; */
}

.table-header > tr > th {
  width: 200px;
}

.table-row {
  display: flex;
  width: 100%;
  padding: 12px 0;
  border-bottom: #e8e8e8 solid;
}

.table-data {
  width: 200px;
}

thead > tr > th {
  /* width: 200px; */
}

.table-row:nth-of-type(odd) {
  /* background: #EEEEEE; */
}

.table-data, .header__item {
  flex: 1 1 20%;
  text-align: center;
}

.header__item {
  text-transform: uppercase;
}

.filter__link {
  color: white;
  text-decoration: none;
  position: relative;
  display: inline-block;
  padding-left: 24px;
  padding-right: 24px;
}
.filter__link::after {
  content: "";
  position: absolute;
  right: -18px;
  color: white;
  font-size: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.filter__link.desc::after {
  content: "(desc)";
}
.filter__link.asc::after {
  content: "(asc)";
}

.pagination-section {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

/* ANTD OVERRIDES START */
.ant-tabs-tab {
  color: #949596;
}
.ant-tabs-tab:hover {
  color: #949596 !important;
}
.ant-tabs-tab-active {
  color: #1677FF;
}
.ant-tabs-tab-active:hover {
  color: #1677FF !important;
}
/* ANTD OVERRIDES END */


/* QUILL OVERRIDES START */
.quill {
  /* border: 1px solid black; */
  border-radius: 10px;
  overflow: auto;

  border-radius: 8px;
  border: 0.5px solid rgba(0, 0, 0, 0.20);
  background: #FFF;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  display: block;
  margin-top: -1px;
  top: unset !important;
  bottom: 100%;
  z-index: 1;
}

.quill > div:nth-child(1) {
  position: fixed;
  width: auto;
  background-color: white;
  border: none;
  z-index: 1020;
  border-radius: 0 0 10px 10px;
}
.quill > div:nth-child(2) {
  border: none;
  width: auto;
  padding-top: 10px;
  padding-bottom: 45px;
}
.ql-tooltip .ql-editing .ql-flip {
  top: none !important;
  bottom: 0 !important;
  right: 0;
  position: unset !important;
  transform: translateY(0px) !important;
  transform: translateX(120px);
}

.ql-container > div:nth-child(3)[style] {
  height: 40px;
  bottom: 30px !important;
  top: unset !important;
  left:130px !important;
  /* right: 0 !important; */
}
/* QUILL OVERRIDES END */


.modal-override > div:nth-child(2) {
  background-color: #F6FAFF;
  border-radius: 6px;
}
.modal-override > div:nth-child(2) > div:nth-child(1) > div:nth-child(2) > div:nth-child(1),
.modal-override > div:nth-child(2) > div:nth-child(1),
.modal-override > div:nth-child(2) > div:nth-child(1) {
  background-color: #F6FAFF;
}
.modal-override .ant-modal-content {
  background-color: #F6FAFF !important;
}
.modal-override .ant-modal-title{
  background-color: #F6FAFF;
}
.modal-override .ant-modal{
  border-radius: 400px;
}
.modal-override .ant-modal-content {
  border-radius: 10px;
  width: 600px;
}

.primary {
  background: rgba(23, 119, 255, 0.10) !important;
}
.secondary {
  background: rgba(227, 110, 44, 0.1) !important;
  border-color: rgba(227, 154, 44, 1) !important;
  color: rgba(227, 154, 44, 1) !important;
}
.disable {
  border: 1px solid #AFAFAF !important;
  background: #DCDCDC !important;
}
.danger {
  background: rgba(255, 77, 79, 0.10) !important;
}
.button-override {
  height: auto;
  width: auto;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 10px;
  border-radius: 4px !important;
  cursor: pointer;
}

.link {
  text-decoration: underline !important;
}

.input-number-override {
  height: 35px;
  border-radius: 4px !important;
  /* border: var(--Number, 1px) solid rgba(0, 0, 0, 0.10) !important; */
  padding: 2px;
}
.input-override {
  height: 35px;
  border-radius: 4px !important;
  /* border: var(--Number, 1px) solid rgba(0, 0, 0, 0.10) !important; */
  padding: 5px 12px;
}
.input-number-override::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #A1A9B1;
}
.input-number-override > div {
  border: none !important;
  box-shadow: none;
}
.select-input-override {
  min-height: 35px;
  font-size: 14px;
  font-weight: 400;
  color: #A1A9B1;
  border-radius: 4px !important;
  /* border: var(--Number, 1px) solid rgba(0, 0, 0, 0.08) !important; */
}
.select-input-override > div:nth-child(1) {
  /* border: var(--Number, 1px) solid rgba(0, 0, 0, 0.08) !important; */
  border-radius: 4px !important;
  min-height: 35px;
}

.multi-select-input-override {
  min-height: 35px;
  font-size: 14px;
  font-weight: 400;
  color: #A1A9B1;
  border-radius: 4px !important;
  border: var(--Number, 1px) solid rgba(0, 0, 0, 0.08) !important;
}
.multi-select-input-override > div:nth-child(1) {
  border: none !important;
  border-radius: 4px !important;
}

.ant-select-selector:focus-visible {
  box-shadow: none !important;
  border: none;
}

.ant-form-item-required {
  width: 100%;
}

.custom-table > div > div > div > div > div > div > table > thead > tr > th{
  background-color: #CEE6FA !important;

}
.ant-picker-clear{
  .anticon-close-circle{
    display: none;
  }
}

.date-picker-override {
  height: 35px;
  border-radius: 4px !important;
  padding: 2px;
}
.date-picker-override > div {
  padding: 0 10px;
}
.input-custom-number-override {
  height: 35px;
  border-radius: 0 4px 4px 0 !important;
  border-left: 0;
  padding: 2px;
}
.input-custom-override {
  height: 35px;
  border-radius: 0 4px 4px 0 !important;
  padding: 5px 12px;
}
.input-custom-number-override::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #A1A9B1;
}
.input-custom-number-override > div {
  border: none !important;
  box-shadow: none;
}
.select-custom-input-override {
  min-height: 35px;
  font-size: 14px;
  font-weight: 400;
  color: #A1A9B1;
  border-radius: 4px 0 0 4px !important;
}
.select-custom-input-override > div:nth-child(1) {
  border-radius: 4px 0 0 4px !important;
  min-height: 35px;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #02b520 !important;
}

.ant-input-group .ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: black !important
}

.ant-progress-text {
  display: none !important;
}

.ant-progress-outer {
  padding-inline-end: none !important;
}

.ant-input::placeholder {
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.88;
}

/* .ant-modal-content {
  width: fit-content;
} */

.double-override {
  min-height: 35px;
  font-size: 14px;
  font-weight: 400;
}

.required-star::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  content: "*";
}