.signin::after
    content: ""
    display: block
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-image: linear-gradient(120deg, #eaee44, #33d0ff)
    opacity: .7

.signin
    background-size: cover
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    width: 100%
    position: absolute

.signin-container
    z-index: 1
    padding: 2rem

.signin-container > div
    background-color: white
    border-radius: 4px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.dashboard
    display: flex
    height: 100%
    width: 100%
    background-color: #F8FBFD

.dashboard-container
    width: 100%

.dashboard-pages

.container
    margin-left: 15rem
    width: 75%
    background-color: #001628
    background-color: #001628

.page-container
    // padding: 20px 40px
    height: 80vh
    background-color: white
    border-radius: 20px 0 0 0
    border-radius: 20px 0 0 0
    overflow: scroll

.form-container
    width: 100%
    padding: 20px 40px

.form-column
    max-width: 40%
    width: 100%
    margin-right: 30px

.form-column-spoc
    display: flex
    flex-direction: row
    align-items: center
    gap: 10px
    width: 100%
    margin-bottom: 0px

.form-column-spoc > *
    flex: auto
    width: 100%

.page-header
    padding: 10px 0
    display: flex
    justify-content: end

.secondary-field
    margin: 0 0 25px 0
    width: 100%

.primary-heading
    font-size: 16px
    font-weight: 700

.online-status
  text-align: center
  background-color: #ff4d4f
  color: white
  margin-left: 15rem
  width: auto


.container-layout
  padding: 32px
  background-color: #F6FAFF
  min-height: 100%
  height: auto

.tab

.tab-heading
  font-size: 16px
  font-weight: 500
  padding: 8px 12px

.sub-tab

.sub-tab-heading

.container-body
  margin-top: 18px

.container-inner
  margin-top: 10px

.container-inner-header
  display: flex
  justify-content: space-between
  align-items: center

.container-inner-header-title-item

.container-inner-header-action-item

.container-header-title-section-two
  display: flex
  align-items: center
  margin-top: 5px

.container-header-title-section-two > article
  font-size: 12px
  color: #1F1F1F
  font-weight: 400
    
.table-text-style
    font-size: 12px
    font-weight: 500

.quote-text 
    width: 25%
    font-size: 14px
    padding: 15px

@media only screen and (max-width: 830px) 
    .form-container
        flex-direction: column
    .form-column
        width: 100%

@media only screen and (max-width: 1320px)
    .form-column
        // max-width: 100%
        width: 50%
        margin-right: 30px

@media only screen and (max-width: 1100px)
    .container
        margin-left: 0rem

@media only screen and (max-width: 690px)
    .form-column-spoc
        flex-direction: column

@media only screen and (max-width: 1100px)
    .page-container
        border-radius: 0
    .online-status
        margin-left: 0rem

.ant-select-arrow
    color: black !important

.ant-divider 
    border-block-start: 1px solid rgba(5, 5, 5, 0.2)

.select-input-custom > div > span
    font-size: 12px

.shimmer-ui
  height: 100%
  width: 100%
  border-radius: 4px
  background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%)
  background-size: 300%
  background-position-x: 100%
  animation: shimmer 1s infinite linear

@keyframes shimmer 
  to
     background-position-x: 0%



.deleteIconHover
    position: relative
.deleteIcon
    position: absolute
    right: 0
    margin-right: 0px
    transform: translate(-20%, 60%)
    display: none
    cursor: pointer
.deleteIconHover:hover .deleteIcon 
    display: block
.credit-description > div
    padding: 0 !important
.credit-description
    margin: 0 10px 10px 0
    width: 32%
    height: fit-content
    overflow: hidden
    margin: 0 10px 10px 0
    border: none
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05)

.info-text-label 
    font-size: 12px
    font-weight: 500
    color: "#242424"

.info-text-data 
    font-size: 14px
    font-weight: 400
    color: "#404040"

.form-grid
    display: flex

.form-grid > div
    color: white
    display: flex
    justify-content: center
    align-items: center
    text-align: center