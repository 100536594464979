/* Custom CSS for modal */

.modal-wrapper-request-number {
  position: absolute;
  /* top: 0px; /*Adjust as needed*/
  /* left: 0px; Adjust as needed */
  transform: translate(-25%,110px);
}

.modal-wrapper-request-number > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-request-number > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}

.modal-wrapper-prospect {
  position: fixed;
  top: 100px; /* Adjust as needed */
  left: 100px; /* Adjust as needed */
    transform: translate(0%, 30px);
}

.modal-wrapper-prospect > div {
  margin: 0 !important;
  top: 210px;
  left: 380px;
}

.modal-wrapper-prospect > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-prospect > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}

.modal-wrapper-requirement {
  position: fixed;
  top: 0px; /* Adjust as needed */
  left: 0px; /* Adjust as needed */
  transform: translate(-10%,130px);
}

.modal-wrapper-requirement > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-requirement > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}

.modal-wrapper-owner {
  position: fixed;
  top: 100px; /* Adjust as needed */
  left: 80px; /* Adjust as needed */
  transform: translate(-5%,130px);
  }

.modal-wrapper-owner > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-owner > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}

.modal-wrapper-buyingCity {
  position: fixed;
  top: 100px; /* Adjust as needed */
  left: 100px; /* Adjust as needed */
  transform: translate(5%, 130px);
  }

.modal-wrapper-make {
    position: fixed;
    top: 100px; /* Adjust as needed */
    left: 100px; /* Adjust as needed */
    transform: translate(15%, 130px);
}

.modal-wrapper-model {
  position: fixed;
  top: 100px; /* Adjust as needed */
  left: 100px; /* Adjust as needed */
  transform: translate(20%, 130px);
}

.modal-wrapper-variant {
  position: fixed;
  top: 100px; /* Adjust as needed */
  left: 100px; /* Adjust as needed */
  transform: translate(28%, 130px);
}



.modal-wrapper-buyingCity > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-buyingCity > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}

.modal-wrapper-request-prospect {
  position: fixed;
  top: 100px; 
  left: 100px;
  transform: translate(-270px, 100px);
  }

.modal-wrapper-request-prospect > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-request-prospect > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}
.modal-wrapper-request-owner {
  position: fixed;
  top: 100px; 
  left: 100px;
  transform: translate(-90px, 100px);
  }

.modal-wrapper-request-owner > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-request-owner > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}
.modal-wrapper-updated-by {
  position: fixed;
  top: 100px; 
  left: 100px;
  transform: translate(510px, 100px);
  }

.modal-wrapper-updated-by > div > div:nth-child(2) {
  padding: 8px;
}

.modal-wrapper-updated-by > div > div:nth-child(2) > div:nth-child(2) {
  padding: 0;
}

/* PROSPECT DETAILS STYLING START */
.parent-container {
  position: relative;
  overflow: scroll;
  height: 100%;
  background-color: #F6FAFF;
}

.container-layout {
  padding: 32px;
  /* background-color: #F6FAFF; */
  min-height: 100%;
  height: auto;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: self-start;
}

.container-header-title {

}

.container-header-title-section-one {
  display: flex;
  align-items: center;
  /* max-width: 400px; */
  font-size: 20px;
  margin: 0;
}

.container-header-title-section-one > h4 {
  margin: 0;
  font-weight: 700;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}
.container-header-title-section-one > span {
  font-size: 14px;
  padding: 2px 8px;
  margin-left: 10px;
}

.container-header-title-section-two {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.container-header-title-section-two > article {
  font-size: 12px;
  color: #1F1F1F;
  font-weight: 400;

}
.container-header-title-section-two > article:last-child {
  color: #5C5C5C;
  font-weight: 500;
}

.container-header-action {}

.tab {}

.tab-heading {
  font-size: 16px;
  font-weight: 500;
  padding: 8px 12px;
}

.sub-tab {}

.sub-tab-heading {}

.container-body {
  margin-top: 18px;
}

.container-inner {
  margin-top: 10px;
}

.container-inner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-inner-header-title-item {

}

.container-inner-header-action-item {

}

.container-inner-section-body {}
.container-inner-section-body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container-inner-section-body-section {
  margin-top: 20px;
}

.edit-section > button {
  margin-left: 10px;
}

.list-item-container, .list-item-container-editable {
  margin: 8px 0;
  align-items: center;
}
.document-list-item-container{
  margin: 8px 0;
  align-items: center;
  font-size: 12px;
  font-weight: 600;}
.list-item-container-editable > div {
  width: 20%;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 600;
}
.list-item-container > div {
  width: 25%;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 600;
}
.document-list-item-container > div {
  /* width: 20%; */
  padding: 0 12px;
  font-size: '12px';
  font-weight: 700;
  
}
.list-item {
  background-color: #E6F2FD;
  border-radius: 8px;  
}
.list-item > div {
  font-weight: 400 !important;
}

.detail-description-item-container {
  display: flex;
  flex-wrap: wrap;
}
.detail-description-item {
  padding-right: 20px;
  padding-bottom: 24px;
  width: 25%;
}

.multine-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.description-label, .form-label > div > div:nth-child(1) > label {
  color: #242424;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 4px;
}

.notes-container {
  position: fixed;
  bottom: 0;
  right: 32px;
  width: auto;
  height: auto;
  z-index: 1111;
  border-radius: 8px 8px 0 0;
  background-color: #F6FAFF;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
}
/* .upload-container {
  position: fixed;
  padding: 20px;
  top: 20%;
  left: 20%;
  max-height: 35rem;
  z-index: 2;
  border-radius: 8px 8px 0 0;
  background-color: #F6FAFF;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
} */
/* PROSPECT DETAILS STYLING END */

.requirement-card-info-container > div {
  padding: 16px !important;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border: none !important;
}

.fixed-header-modal {
  .ant-modal-content {
    border-radius: 10px; /* Example: Add border-radius */
  }
}

.fixed-header {
  width: 80%;
  position: fixed;
  font-size: 16px;
  font-weight: 500;
  color: #404040;
  border-radius: 8px 8px 0 0;
  background-color: #f6faff;  
  padding: 16px 20px 10px 20px;
  z-index: 1;
}

.change-tag {
  background-color: #FFF3E0;
  color: #FFA800;
  border-radius: 4px;
  font-size: 10px;
  padding: 4px;
  margin-left: 8px;
}
.requirement-card-info {
  width: 20%; 
  padding: 10px 5px;
  color: #404040;
}
.requirement-card-info-wide {
  width: 40%; 
  margin: 5px 0;
  color: #404040;
}
.requirement-modal > div:nth-child(2) > div {
  padding: 0;
  background-color: #F6FAFF;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
}

.preview-modal > div:nth-child(2) > div {
  background: #F6FAFF;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
}

/* style={{backgroundColor: "#FFF3E0", color: "#FFA800", borderRadius: 4, fontSize: 10, padding: 4, marginLeft: 5}} */

@media only screen and (max-width: 1100px) {
  .modal-wrapper-prospect > div {
    margin: 0 !important;
    top: 210px;
    left: 100px;
  }  
}
.optional-label{
  opacity: 0.3;
  font-weight: 600;
  margin-left: 4px;
}

.document-select-modal > div:nth-child(2)> div:nth-child(1)  {
  padding: 1.2rem;
  background-color: #F6FAFF;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.25);
}