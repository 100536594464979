/* Requirements.css */
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.requirements-heading-text {
    color: "#242424";
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.add-requirements-button {
    padding: 10px 12px; 
    border-radius: 4px; 
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #1777FF; 
    color: #1777FF;
    background-color: #1777FF1A;
    height: auto;
    width: auto;
    font-size: 12px;
    font-weight: 500px;
    padding: 10px 12px;
}
.description-item {
    padding-right: 20px;
    padding-bottom: 24px;
    width: 20%;
  }
  .description-item-wide {
    padding-right: 20px;
    padding-bottom: 24px;
    width: 50%;
  }