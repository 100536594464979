.disabled-form .view-state .ant-form-item-label {
    padding-bottom: 0 !important;
}

.disabled-form .view-state .ant-input-disabled, .disabled-form .ant-input-number, .disabled-form .ant-input-number-input {
    background-color: white;
    border: none !important;
    border-width: 0;
    padding: 0 !important;
    color: black;
}

.disabled-form .ant-input-group-addon{
    padding: 0;
}

.disabled-form .view-state .ant-select-disabled .ant-select-selection-item, .disabled-form .view-state .ant-select-selector, .disabled-form .view-state .ant-picker-disabled {
    color: black;
    background-color: white !important;
    border: none;
    border-bottom: none;
    padding: 0;
}

.disabled-form .view-state .ant-select-disabled .ant-select-selector{
    background-color: white;
    border: none;
    padding: 0;
    color: black
}

.disabled-form .view-state .ant-picker-disabled input {
    color: black !important
}

.disabled-form .ant-input-group-addon {
    border: none
}

.disabled-form .view-state .ant-picker-disabled .ant-picker-suffix, .disabled-form .view-state .ant-select-arrow {
    display: none;
}

.client-onboarding-form .ant-form-item-required::before {
    position: absolute !important;
    right: 0 !important;
    /* display: none !important; */
}

.client-onboarding-form .ant-form-item-required {
    width: auto;
}

#client-details .ant-upload.ant-upload-select {
    width: 100%;
}

.disabled-form .ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
}

label[for="basic-details_segmentName"].ant-form-item-required::before {
    display: none;
}

label[for="basic-details_segmentName"].ant-form-item-required::after {
    display: none;
}