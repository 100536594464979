tr.editable-row td {
    border: none !important;
    font-size: 13px
}

.status-select .ant-select-selection-item {
    padding:  0 !important;
}

th {
    background: #E6F2FD !important;
}
/* 
tbody tr:last-child {
    background-color: #E6F2FD; 
    font-weight: bold;
} */

.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number[type=number] {
  -moz-appearance: textfield;
}

.sum-row, .sum-row .ant-table-cell-row-hover {
    background-color: #e6f2fd !important;
    font-weight: bold;
}