// background - #F8FBFD
// promary - #3561EB

.App
  position: absolute
  height: 100%
  width: 100%

.label
  font-weight: 600
  color: #000

.form-group
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-end

.button-group
  margin: 20px 0
  max-width: 100px