/* CustomSelectFilter.css */

.custom-select-filter {
  width: 100%; /* Adjust width as needed */

}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.item-list {
  /* min-height: 190px; */
  max-height: 150px;
  overflow-y: auto;
  margin: 5px;
}

.item-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 14px;
}

.item-item > label {
  width: 16px;
  height: 16px; 
}

.item-item > span {
  font-size: 14px !important;
}

/* Custom checkbox style */
.custom-checkbox {
  transform: scale(1.2); /* Adjust the scale factor as needed */
  margin: 5px;
}
.disabled-custom-checkbox {
  transform: scale(1.2); /* Adjust the scale factor as needed */
  margin: 5px;
}
.disabled-custom-checkbox .ant-checkbox-inner {
  border-color: #DCDCDC;
  background-color: #DCDCDC;
}

.disabled-custom-checkbox .ant-checkbox-checked  {
  border-color: #DCDCDC;
 
}

.disabled-custom-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #AFAFAF;
 
}

.select-all-checkbox{
  transform: scale(1.5); /* Adjust the scale factor as needed */
  margin-right: 10px; /* Add some margin for spacing */
  margin-left: 23px;
  margin-bottom: 7px;
  margin-top: 5px;
}
/* Button style */
.custom-search-button {
  margin-left: 10px; /* Add margin to separate from input */
}
.placeholder-checkbox {
  transform: scale(1.5); /* Adjust the scale factor as needed */
  margin-left: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
}